import {Controller} from 'stimulus';
import $ from 'jquery';

import Select2 from 'select2';

export default class extends Controller {
  connect() {
    $('#objective_language_ids').select2({
      tags: true,
      tokenSeparators: [',', ' '],
    });

    $('#objective_framework_ids').select2({
      tags: true,
      tokenSeparators: [',', ' '],
    });

    $('#objective_track_ids').select2({
      tags: true,
      tokenSeparators: [',', ' '],
    });

    $('#objective_topic_id').select2();
    $('#objective_skill_level').select2();
  }
}
